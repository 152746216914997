Reservations.vue
<template>
  <div id="">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="12" class="mt-2" style="position: relative">
        <vs-button color="primary" class="mr-3" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'days').toDate(); getReservations()}"></vs-button>
        <datepicker :minimumView="'day'" :maximumView="'day'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    style="width: 100%; text-align: center; margin-top: -2px;"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" class="ml-3" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'days').toDate(); getReservations()}"></vs-button>
      </vs-col>
    </vs-row>

    <div v-if="!reservationsData || reservationsData.length === 0" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni prevozov</em>
        </vs-col>
      </vs-row>
    </div>

    <div class="vx-card mt-5 pl-3 pb-3" v-for="(reservation, idx) in reservationsData" :key="'reservation_' + idx" style="max-width: 860px;">
      <div class="vx-card__header p-0">
        <div class="vx-card__title" style="width: 100%;">
          <vs-row
            vs-align="center"
            vs-type="flex" vs-justify="space-between" class="w-100">
            <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="7"
                    style="flex-direction: row; text-align: left;">
              <a :href="'tel://' + reservation.customer.phone">
                <vs-avatar size="60px" :src="reservation.customer.gender === 'male' ? sir : madam"
                           :color="reservation.customer.gender === 'male' ? '#dff8ff' : '#ffffdf'"
                           style="border: 2px solid #4083ff; margin-right: 14px;" />
              </a>
              <div>
                <h5 style="font-weight: bold"><a :href="'tel://' + reservation.customer.phone">{{
                    reservation.customer.firstName + ' ' + reservation.customer.lastName
                  }}</a></h5>
              </div>

            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5"
                    style="flex-direction: column; text-align: right; padding: 4px; border-radius: 0.5rem; border-bottom-left-radius: 3rem; max-width: 300px; margin-top: -5px; margin-right: -3px; box-shadow: -3px 3px 10px 0px rgba(0,0,0,0.3)" :style="'background-color:' + reservation.car.color">
              <a :href="'tel://' + reservation.car.phones[0].phone" style="cursor: pointer; text-align: center">
              <div>
                <vs-avatar size="46px" :src="$globalFunctions.getUserAvatar(reservation.volunteer.profileImage)" style="margin: 0px; border: 2px solid rgba(255,255,255,0.2);"/>
              </div>
              <div class="text-center">
                <div class="mb-1"><b style="color: white;">{{ reservation.volunteer.firstName + ' ' + reservation.volunteer.lastName }}</b></div>
                <span style="border-radius: 4px; padding: 2px; color: white;"
                      :style="'background-color:' + reservation.car.color"><small>{{
                    reservation.car.name
                  }}</small></span>
              </div>
              </a>
            </vs-col>
          </vs-row>
          <vs-row
            vs-align="center"
            vs-type="flex" vs-justify="space-between" class="w-100">
            <vs-col vs-type="flex" vs-justify="center" vs-align="start" vs-w="12"
                    style="flex-direction: column; text-align: left;">
              <div>
                <div style="width: 100px">
                  <h4 style="display: inline; font-weight: bold">{{ moment(reservation.departureTime).format('HH:mm') }}</h4>
                </div>
                <div>
                  <b>{{ reservation.departure.addressName ? reservation.departure.addressName + ', ' : '' }}</b>{{
                    reservation.departure.quickSearch.replace(', Slovenija', '')
                  }}
                </div>
              </div>
              <div class="mt-2">
                <div style="width: 100px">
                  <h4 style="display: inline; font-weight: bold">{{ moment(reservation.arrivalTime).format('HH:mm') }}</h4>
                </div>
                <div>
                  <b>{{ reservation.destination.addressName ? reservation.destination.addressName + ', ' : '' }}</b>{{
                    reservation.destination.quickSearch.replace(', Slovenija', '')
                  }}
                </div>
              </div>
              <div class="mt-2">
                <div style="width: 100px">
                  <feather-icon icon="HomeIcon" svgClasses="h-6 w-6 mb-1" />
                </div>
                <div>
                  <b>{{ reservation.arrival.addressName ? reservation.arrival.addressName + ', ' : '' }}</b>{{
                    reservation.arrival.quickSearch.replace(', Slovenija', '')
                  }}
                </div>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";

export default {
  name: 'Reservations',

  components: {
    Datepicker,
  },

  data() {
    return {
      slSI: slSI,
      sir: sir,
      madam: madam,
      reservationsData: null,
      selectedReservationId: null,
      sidebarActive: false,
      selectedDate: new Date(),
      mode: null,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
    _this.getReservations();
  },

  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.selectedReservationId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getReservations() {
      const _this = this;

      _this.$vs.loading();

      let startDate = _this.moment(_this.selectedDate).startOf('day');
      let endDate = _this.moment(_this.selectedDate).endOf('day');

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations?unit=' + _this.$globalFunctions.getCurrentUnit().id + '&startDate=' + startDate + '&endDate=' + endDate)
        .then((res) => {
          _this.reservationsData = _.sortBy(res.data.data, function (o) {
            return _this.moment(o.departureTime).valueOf();
          });
          console.log("_this.reservationsData", _this.reservationsData);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    onDateChange() {
      this.getReservations();
    },


    customDateFormatter(date) {
      return this.moment(date).format('dddd, DD. MM. YYYY');
    },

  }
}
</script>

<style language="scss">
.router-view {
  /*padding-left: 0.5rem !important;*/
  /*padding-right: 0.5rem !important;*/
}
</style>
